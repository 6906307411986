<template>
  <div>
    <b-container fluid="xl"> <Navbar /></b-container>
    <div class="hero"><img src="../assets/traplift-header-desktop.jpg" /></div>
    <div class="center">
      <div class="textAlign">
        <p>
          <b>gratis<br /><b>TRAPLIFT</b><br />brochure</b>
          <img class="centerArrow" src="../assets/circle.png" width="75px" />
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";

export default {
  name: "HeaderH",
  components: {
    Navbar,
  },
};
</script>

<style scoped>
.center {
  height: 200px;
  width: 200px;
  background-color: #ff4800;
  border-radius: 50%;
  position: absolute;
  top: 32%;
  left: 26%;
  color: white;
  text-align: center;
  font-size: 25px;
}
.centerArrow {
  margin-left: 63px;
  user-select: none;
}
@media (max-width: 1280px) {
  .center {
    height: 200px;
    width: 200px;
    background-color: #ff4800;
    border-radius: 50%;
    position: absolute;
    top: 34%;
    left: 26%;
    color: white;
    text-align: center;
    font-size: 25px;
  }
  .centerArrow {
    margin-left: 63px;
    user-select: none;
    pointer-events: none;
  }
}

@media (max-width: 915px) {
  .center {
    height: 200px;
    width: 200px;
    background-color: #ff4800;
    border-radius: 50%;
    position: absolute;
    top: 10%;
    left: 20%;
    color: white;
    text-align: center;
    font-size: 25px;
  }
}

@media (max-width: 820px) {
  .center {
    height: 160px;
    width: 160px;
    background-color: #ff4800;
    border-radius: 50%;
    position: absolute;
    top: 11%;
    left: 26%;
    color: white;
    text-align: center;
    font-size: 21px;
  }
  .centerArrow {
    width: 57px;
    margin-left: 53px;
    user-select: none;
  }
}
@media (max-width: 420px) {
  .center {
    height: 80px;
    width: 80px;
    background-color: #ff4800;
    border-radius: 50%;
    position: absolute;
    top: 8%;
    left: 23%;
    color: white;
    text-align: center;
    font-size: x-small;
  }
  .centerArrow {
    width: 35px;
    margin-left: 25px;
    user-select: none;
  }
}
.textAlign {
  color: white;
  margin-top: 14%;
  user-select: none;
}
</style>
