import { RepositoryFactory } from "../../repository/RepositoryFactory";

let leads = RepositoryFactory.get("leads");
const state = {
  getLoader: false,
  id: "",
};

const getters = {
  getLoader: (state) => state.getLoader,
  profId: (state) => state.id,
};

const actions = {
  async postLead({ commit }, payload) {
    commit("setGetLoader", true);
    try {
      const response = await leads.postLead(payload);
      commit("setGetLoader", false);
      const responseid = response.data.profile.id;
      commit("id", responseid);
      return {
        success: true,
        profileid: responseid,
      };
    } catch (error) {
      console.log(error.response);
      commit("setGetLoader", false);
      console.log(error.response.data.error);
      commit("id", null);
      return {
        success: true,
        profileid: null,
      };
    }
  },
};

const mutations = {
  setGetLoader(state, payload) {
    state.getLoader = payload;
  },
  id(state, payload) {
    state.id = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
