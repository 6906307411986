import axios from 'axios';
const baseDomain = 'https://leadgen.republish.nl/api/sponsors/2066';
const baseURL = `${baseDomain}/`;
let axiosObj;
axiosObj = axios.create({
	baseURL,
	headers: {
		Authorization: `Basic MTg1OmFiODIyMWQ0YTMxNzBkODk1NDI4ODA0NTlhYmY3OTgxN2FlMzY3YzI=`,
	},
});
export default axiosObj;